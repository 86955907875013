import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthenticationProvider } from './data/auth/transition';

import './index.css';
import App from './App';
import current from './config';

import './tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AuthenticationProvider
    redirectUri={window.location.href}
    scope="openid profile email"
    cacheLocation="localstorage"
    {...current.auth}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthenticationProvider>
);
