import * as cognitoAuth from './Auth';

export const {
  getAccessTokenSync,
  getAccessToken,
  logout,
  useAuthentication,
  AuthenticationProvider,
  AuthenticationContext,
} = cognitoAuth;
