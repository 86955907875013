import { completePkceAuth, refreshCredentials, revokeToken, retrieveAndValidateState, parseJwtClaims } from '../pkce';
import config from '../../../config';

const { client_id, redirect_uri } = config.auth;

const checkAuthentication = async (context) => {
  const { email } = parseJwtClaims(context.id_token);
  if (!email) {
    throw new Error('Authentication check failed.');
  }
};

const getTokenFromCodeExchange = (context) => {
  const { code, state } = context.url.query;
  const session = retrieveAndValidateState(state);
  return completePkceAuth({ client_id, code, session, redirect_uri });
};

const getTokenFromRefreshToken = (context) => refreshCredentials({ client_id, refresh_token: context.refresh_token });

const revokeRefreshToken = (context) => revokeToken({ client_id, token: context.refresh_token });

export default {
  checkAuthentication,
  getTokenFromCodeExchange,
  getTokenFromRefreshToken,
  revokeRefreshToken,
};
