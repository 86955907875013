import { createMachine } from 'xstate';
import guards from './guards';
import actions from './actions';
import services from './services';

const authenticationMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFmAduglgMbIED2OAssoZvjmAHRpa4HEE5QMzodQAqpANa4AYgCdSAWwDCpCGACiADxrJOYAMQRyjOgDdhjZtjxES+clRp1jGU2wuduYXnQFGcE6XIUq1GggGpOyWOADaAAwAuoigAA6ksPhkOHEgyogAbAAcAJwMAOwArJGRAEx5OYU5AIy1eQAsADQgAJ6IAMyRWQx5NTlZxeXltT3FjVkAvlOtJqzmqda09Ez2C6HOPHyCIl6SsvJKqpjqMJpg4pLiDPEANiQAZqTiUmssZqFW1Ct2H458FxuTi7MQHXzHAIwII4QxfCIxdKJZKpdKZBD1Mp9SZ5TrdSLFYqdPKtDoYnLlBiNHLFPJlIaNIkEmZzdafCzfGyrebswHbdyg-bSABKYEe4jgmEF2l0DGCIneDkWYWWtkVGycXH5IM83ikovFksFMLhHIRsSQIGRKTCaK6eIYnUG1VqeJqdKypMQ5UKjQYtSyJU6hNKORyjUZLJAPIBKp+apjyq2rh2uoOBolsClnguVxetwe6Ger3VvLjXL+Ss2WpTArTIrFmezexNITNUQtCSSNvIdoQeM6judhVdnXdPS9-aykSpkUGkVqwyqYc601m0bZsc5v1LsecNDAhCE7gAgpvleQZat5ZWNUt49zz9WGAej6en2bW-CO0ju6jLei9SNDOTRZLi+KEsSk6NPU2LhhUTJZDBi5Rom8Kqo+-xJlwr7HpwZ5YfCubXAWTwvG8aFmhht5lvu2BvvhH6pF+7aIpa1r-qAgGuiBOJ4mUkEku02R5AUnSNKMhQjCG5RhqhTHljulzXJoEroOIZJdiitoAd6i6FAwxQ1AGtSMjBEydJOhRZJStK1D6hRzlkQz1PJhFUQ+jDKS8mh3KQUCkBgv7ab2ukIKMRmGcZWSmRMi6NJZwkIIGxQMFkToBsUi5juJxRuVWHkVgwflQDAEAAJI4AwEqGlmp6EIQcCwNKOjXrCRi7helCecV-llZV1WNpK9WNbAzWeCxqQ-uxf46VxiCLhUVLASlhSiWBY6TmOBR5DZjI5BUzm4oU+V3opaolf1VU1U2I1NdK3k3PcZElpR95FZdkADTdw34Q190TcE35sVpPZpGFtT5AZokZQGSGlDZk5Mv6eTFAdTqVIyNmnWW24XX1X1VegngAGrIHc+AQJouGCio8T4BpwVg32pmNAZ5S0gluR5JDoktElEnQ+J4mOblYYneub3nasn0VTgvn+YF6BM5xGQLVDfS4nUcOMj05TQQlDDlBJYG80MxKEjjW7dUVsDoMg4joJob1gCrc1qxism9KjnTlASSFDCUk7hgUdRSaZpllBFMzrjgRzwJaUt45hBV8rWOp7HqEL+KcGhu6F80IMBM61COdLDGzeR+zkk4BtDdTSYUq4JaOVtddRnXPtqHiZ+mQ1ZoK+fg4XkRbd0fQDEMIxjAya6su5707lLdGHnhUAEan7sce76LhoOnSLoG1khjzhRbQuE+Q4yi7hjUbfoT1y9QEPfYALTG5OqOa5E1k5MGaPiUDPfQqSk8ziBfmFP2YEGBhh4lJGoeJdqTjAgZGKCD0oUkcj0YBi98alUgAAeSCjNEKw8PblF9gUSYxRA49DWtUT0AtGgFB6A0A+pl8gEl2jg6WjBZbfX7isde-0xqDxIczCGAcGD0l9sGQoI4hhCTJBGSkFDKFGWnPkC2PDk58IJnLBgxM9hkwphACBhdXSmUMvZNGP8-4LnqNBJuRRq5EiaKuekOibY7n4WQ7eBcPb2TpNYjmB0EEONqJODm3ty65EiAlD01kvEdztg7ZW4jVbohGIyf0Y5hjlBsnFeRVkkJGzZmUYMDR5GdGSZ5cxHtX7UiiX6MokQD6RH6MMJ0FQakxyAA */
  createMachine(
    {
      context: {},
      predictableActionArguments: true,
      id: 'authenticationMachine',
      initial: 'start',
      states: {
        authenticating: {
          initial: 'gettingTokenFromRefreshToken',
          states: {
            gettingTokenFromCodeExchange: {
              invoke: {
                src: 'getTokenFromCodeExchange',
                onDone: [
                  {
                    actions: 'updateContext',
                    target: 'checkingAuthentication',
                  },
                ],
                onError: [
                  {
                    target: '#authenticationMachine.error',
                  },
                ],
              },
            },
            gettingTokenFromRefreshToken: {
              invoke: {
                src: 'getTokenFromRefreshToken',
                onDone: [
                  {
                    actions: 'updateContext',
                    target: 'checkingAuthentication',
                  },
                ],
                onError: [
                  {
                    target: '#authenticationMachine.error',
                  },
                ],
              },
            },
            checkingAuthentication: {
              invoke: {
                src: 'checkAuthentication',
                onDone: [
                  {
                    target: '#authenticationMachine.loggedIn',
                  },
                ],
                onError: [
                  {
                    target: '#authenticationMachine.error',
                  },
                ],
              },
            },
          },
        },
        error: {
          entry: 'updateErrorMessageFromEvent',
          always: {
            // TODO: more sophisticated error handling
            target: 'loggedOut',
          },
        },
        loggedOut: {
          entry: 'clearRefreshToken',
          invoke: {
            src: 'revokeRefreshToken',
            onDone: {
              actions: ['clearContext', 'startAuthentication'],
            },
          },
        },
        loggedIn: {
          entry: 'updateUserInfo',
          initial: 'tokenValid',
          states: {
            refreshingAccessToken: {
              invoke: {
                src: 'getTokenFromRefreshToken',
                onDone: [
                  {
                    target: 'tokenValid',
                    actions: 'updateContext',
                  },
                ],
                onError: [
                  {
                    target: 'errorRefreshingAccessToken',
                  },
                ],
              },
            },
            errorRefreshingAccessToken: {
              always: {
                // TODO: better retry + delay handling on error
                target: 'tokenValid',
              },
            },
            tokenValid: {
              entry: ['checkAccessTokenExpiryAfterDelay', 'saveRefreshToken'],
              on: {
                checkTokenExpiry: [
                  {
                    cond: 'tokenExpiryUpcoming',
                    target: 'refreshingAccessToken',
                  },
                  {
                    actions: 'checkAccessTokenExpiryAfterDelay',
                  },
                ],
              },
            },
          },
          on: {
            logout: {
              target: 'loggedOut',
            },
          },
        },
        start: {
          entry: ['initializeContext', 'run'],
          on: {
            authenticate: [
              {
                cond: 'haveRefreshToken',
                target: '#authenticationMachine.authenticating.gettingTokenFromRefreshToken',
              },
              {
                actions: 'updateContextFromUrlFragment',
                cond: 'tokensInUrlFragment',
                target: '#authenticationMachine.authenticating.checkingAuthentication',
              },
              {
                cond: 'pkceStateInUrl',
                target: '#authenticationMachine.authenticating.gettingTokenFromCodeExchange',
              },
              {
                target: 'loggedOut',
              },
            ],
          },
        },
      },
    },
    { guards, actions, services }
  );

export default authenticationMachine;
