import { useAuthentication } from '../../data/auth/transition';

const Authenticator = (props) => {
  const { isLoading, isAuthenticated } = useAuthentication();
  const loadingComponent = props.loading;

  if (isLoading || !isAuthenticated) {
    return loadingComponent();
  }
  return props.children;
};

export default Authenticator;
