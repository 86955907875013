const config = {
  auth: {
    domain: 'cognito.benefitsapi.com',
    client_id: '6l7jeu4r44kgndgeab4aot355m',
    signOnUrl: process.env.REACT_APP_SIGN_ON_URL || 'https://signon.benefitsapi.com/',
    client: process.env.REACT_APP_AUTH_CLIENT || 'drop',
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT || 'https://drop.getbenepass.com/',
  },
  plaid: {
    environment: 'production',
  },
  api: {
    origin: process.env.REACT_APP_BENEFITS_API || 'https://api.benefitsapi.com/',
  },
};

export default config;
